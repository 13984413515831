import React from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
    <Helmet>
      <meta name="description" content="Learn more about Shivaytech Innovation and our journey in providing innovative digital solutions." />
      <meta name="keywords" content="About Shivaytech, Shivaytech Innovation, digital solutions" />
    </Helmet>
    <section
      className="bg-primary1 py-12 mt-16 rounded-br-[220px] rounded-tl-[220px] md:rounded-br-[180px] md:rounded-tl-[180px] sm:rounded-br-[120px] sm:rounded-tl-[120px]"
      aria-label="About Us Section"
    >
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left Column */}
          <div className="text-white pl-10">
            <h1 className="text-3xl font-bold mb-4 text-center md:text-left">About Us</h1>
            <p className="text-lg mb-6 text-center md:text-left">
              We are dedicated to delivering exceptional services that help your business thrive.
              Our team specializes in creating unique and effective solutions tailored to your needs.
            </p>
            {/* <div className="flex justify-center md:justify-start">
              <a
                href="/about"
                className="bg-primary2 text-white py-2 px-6 rounded-full hover:bg-white hover:text-primary2 transition-all duration-300"
                aria-label="Read more about us"
              >
                Read More
              </a>
            </div> */}
          </div>

          {/* Right Column */}
          <div className="flex justify-center">
            <img
              src="/shivaytech-about-img.png"
              alt="shivaytech Innovation | Illustration representing our team and mission"
              className="max-w-5xl max-h-[250px] md:max-h-[380px] md:max-w-4xl sm:max-h-[220px] object-cover"
            />
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default About;
