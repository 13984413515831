import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { Pagination, Autoplay } from "swiper/modules";

const slides = [
  {
    image: "shivaytech-slide2.png",
    title: "Web Development",
    description:
      "Transform Your Vision into Reality. Stunning Websites & Scalable Apps Tailored to Your Needs!",
    link: "/web-development",
  },
  {
    image: "shivaytech-slide1.png",
    title: "Logo Design",
    description:
      "Your Brand, Your Identity. Crafting Memorable Logos That Leave a Lasting Impression.",
    link: "/logo-design",
  },
  {
    image: "shivaytech-slide3.png",
    title: "Digital Marketing",
    description:
      "Grow Your Business Online. Expert Strategies to Drive Traffic, Boost Engagement, and Increase Sales!",
    link: "/digital-marketing",
  },
];

const ServiceSlider = () => {
  return (
    <section
      className="w-full flex items-center justify-center bg-primary1 rounded-br-[220px] sm:h-[100vh] h-[80vh]"
      aria-label="Service Slider"
    >
      <div className="w-full h-full relative scroll-smooth">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          pagination={{
            clickable: true,
            renderBullet: (index, className) =>
              `<span class="${className} w-4 h-1 bg-white rounded-full inline-block mx-1"></span>`,
          }}
          modules={[Pagination, Autoplay]}
          className="h-full"
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              key={index}
              className="relative w-full h-full flex items-center justify-center"
              aria-labelledby={`slide-title-${index}`}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 w-full h-full items-center p-4 md:p-8">
                {/* Left Column: Image */}
                <div className="flex items-center justify-center">
                  <img
                    src={slide.image}
                    alt="Shivaytech web development services"
                    className="max-w-full md:max-w-5xl max-h-[280px] md:max-h-[450px] object-contain"
                    loading="lazy"
                  />
                </div>

                {/* Right Column: Text */}
                <div className="text-center text-white px-4 py-4 md:px-8 md:pb-6 md:pt-0 max-w-lg mx-auto md:mx-0">
                  <h1
                    id={`slide-title-${index}`}
                    className="text-xl md:text-4xl font-bold mb-4 whitespace-pre-wrap"
                  >
                    {slide.title}
                  </h1>
                  <p className="mb-4 text-sm md:text-base leading-relaxed">
                    {slide.description}
                  </p>
                  <a
                    href={slide.link}
                    className="inline-block bg-purple-900 text-white py-2 px-4 rounded hover:bg-purple-800 hover:-translate-y-1 hover:scale-105 transition-transform duration-300"
                    aria-label={`Learn more about ${slide.title}`}
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default ServiceSlider;
