import React from 'react'; // Make sure to import your image
import { FaCheckCircle } from 'react-icons/fa'; // Import approved symbol icon
import whimg from "../assets/img/website-hosting.jpeg"


const data = [
  {
    type: 'Shared Hosting',
    functionalities: 'Cost-effective, ideal for small websites and blogs',
    example: 'Bluehost, HostGator',
    costEstimate: '$2.95 to $10/month',
  },
  {
    type: 'VPS Hosting',
    functionalities: 'Dedicated resources, better performance, suitable for growing sites',
    example: 'InMotion Hosting, A2 Hosting',
    costEstimate: '$20 to $100/month',
  },
  {
    type: 'Dedicated Hosting',
    functionalities: 'Full control over the server, high performance, ideal for large businesses',
    example: 'Liquid Web, HostGator',
    costEstimate: '$80 to $500/month',
  },
  {
    type: 'Cloud Hosting',
    functionalities: 'Scalable resources, high reliability, pay-as-you-go pricing',
    example: 'Amazon Web Services, Google Cloud',
    costEstimate: '$10 to $300+/month',
  },
  {
    type: 'WordPress Hosting',
    functionalities: 'Optimized for WordPress, easy installation, automatic updates',
    example: 'WP Engine, SiteGround',
    costEstimate: '$15 to $300/month',
  },
  {
    type: 'Reseller Hosting',
    functionalities: 'Allows you to sell hosting services, ideal for web agencies',
    example: 'A2 Hosting, InMotion Hosting',
    costEstimate: '$20 to $100/month',
  },
  {
    type: 'Managed Hosting',
    functionalities: 'Comprehensive support, server management included',
    example: 'Kinsta, WP Engine',
    costEstimate: '$30 to $300+/month',
  },
  {
    type: 'Colocation Hosting',
    functionalities: 'Physical space in a data center for your servers, high security',
    example: 'DigitalOcean, Rackspace',
    costEstimate: '$50 to $1000+/month',
  },
];

const WebHostingPage = () => {
  return (
    <section className="py-16 px-10 bg-white">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center mb-12">Web Hosting Services</h1> {/* Increased bottom margin for padding */}
        
        <div className="flex flex-col md:flex-row">
          {/* Left Column */}
          <div className="md:w-1/2 md:pr-4 mb-8">
            <h2 className="text-2xl font-semibold mb-4">About Our Web Hosting</h2>
            <p className="text-gray-700 mb-4">
              Our web hosting solutions are designed to meet a variety of needs, from personal blogs to high-traffic business websites. 
              We provide reliable, secure, and scalable hosting options to ensure your online presence is always available and performing optimally.
            </p>
            <h3 className="text-lg font-semibold mb-2">Services We Offer:</h3>
            <ul className="list-disc list-inside mb-4">
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Shared Hosting
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                VPS Hosting
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Dedicated Hosting
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Cloud Hosting
              </li>
            </ul>
          </div>

          {/* Right Column */}
          <div className="md:w-1/2 md:pl-4 mb-8">
            <img src={whimg} alt="Web Hosting Services" className="rounded-lg shadow-lg" />
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8 underline">Our Pricing</h2>
        <div className="overflow-x-auto px-20 pt-5">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-gray-600">
                <th className="border border-gray-300 px-4 py-2">Type</th>
                <th className="border border-gray-300 px-4 py-2">Functionalities</th>
                <th className="border border-gray-300 px-4 py-2">Example</th>
                <th className="border border-gray-300 px-4 py-2">Cost Estimate</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-white">
                  <td className="border border-gray-300 px-4 py-2">{item.type}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.functionalities}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.example}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.costEstimate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8">Technologies We Use</h2>
        <p className="text-gray-700 text-center mb-4">
          Our hosting infrastructure is powered by state-of-the-art technology to deliver speed and reliability.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 my-8 mx-auto max-w-4xl">
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fab fa-linux text-black text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Linux Servers</h3>
            <p className="text-gray-600">Stable and secure hosting environment for your applications.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fab fa-windows text-green-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Windows Servers</h3>
            <p className="text-gray-600">Ideal for .NET applications and Windows-based websites.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-cloud text-gray-700 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Cloud Infrastructure</h3>
            <p className="text-gray-600">Flexible hosting that scales with your business needs.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-lock text-green-600 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">SSL Certificates</h3>
            <p className="text-gray-600">Secure your website with industry-standard encryption.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-database text-indigo-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Database Management</h3>
            <p className="text-gray-600">Support for MySQL, PostgreSQL, and other databases.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-cogs text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">24/7 Support</h3>
            <p className="text-gray-600">Expert assistance whenever you need it, day or night.</p>
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8">Understanding Our Hosting Process</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-gray-700 text-center mb-8 max-w-5xl mx-auto">
          <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl animate-fade-in-up">
            <i className="fas fa-user-plus text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">01. Consultation</h3>
            <p>Discuss your hosting needs with our experts to find the perfect solution for your website.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl animate-fade-in-up">
            <i className="fas fa-server text-green-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">02. Setup</h3>
            <p>We handle the technical setup, ensuring your website is live and running smoothly.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl animate-fade-in-up">
            <i className="fas fa-check-circle text-orange-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">03. Monitoring and Support</h3>
            <p>Ongoing monitoring and support to keep your website secure and performing at its best.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WebHostingPage;
