import React from 'react';



const teamMembers = [
  {
    name: 'Shrikant Jamale',
    role: 'Director + Developer',
    imgSrc: 'Shivaytech-shrikant-jamale.png',
  },
  {
    name: 'Prathaviraj More',
    role: 'Graphic Designer',
    imgSrc: 'Shivaytech-prathaviraj-more.png',
  },
  {
    name: 'Arjun Hanwate',
    role: 'Full-Stack Developer',
    imgSrc: 'Shivaytech-arjun-hanwate.png',
  },
  {
    name: 'Suraj Talole',
    role: 'Cloud Engineer',
    imgSrc: 'Shivaytech-suraj-talole.png',
  }
];

const Team = () => {
  return (
    <section className="team py-16 bg-white px-6 sm:px-10">
      <div className="container mx-auto px-4">
        <header className="text-center mb-12 sm:px-20 md:px-28">
          <h3 className="text-3xl sm:text-3xl font-bold mb-4 text-gray-800">Meet Our Team</h3>
          <p className="text-lg sm:text-lg text-gray-600">
            Discover the exceptional talent and passion driving Yahshua Software Ltd. Our team is dedicated to delivering exceptional results for your business.
          </p>
        </header>

        <div className="flex flex-wrap -mx-4">
          {teamMembers.map((member, index) => (
            <div key={index} className="w-full sm:w-1/2 lg:w-1/4 px-4 mb-8">
              <div className="card bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden relative group">
                <a>
                  <div
                    className="absolute inset-0 bg-primary1 scale-0 group-hover:scale-100 transition-transform duration-5000 origin-bottom-right z-0"
                  ></div>
                  <img
                    src={member.imgSrc}
                    alt={member.name}
                    className="w-full h-64 object-cover rounded-t-lg z-10 relative"
                  />
                  <div className="p-4 text-center relative z-10">
                    <h4 className="text-lg sm:text-xl font-semibold mb-2">{member.name}</h4>
                    <p className="text-sm sm:text-base text-gray-600 group-hover:text-white transition-colors duration-300">{member.role}</p>

                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
