import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import seo from "../assets/img/seo.jpg"


const seoData = [
    {
      type: 'Keyword Research',
      functionalities: 'Identify high-traffic, low-competition keywords for better ranking',
      example: 'Ahrefs, SEMrush',
      costEstimate: '$500 to $3,000+',
    },
    {
      type: 'On-Page Optimization',
      functionalities: 'Title tags, meta descriptions, header tags, image optimization',
      example: 'Yoast SEO, Moz',
      costEstimate: '$1,000 to $5,000+',
    },
    {
      type: 'Content Marketing',
      functionalities: 'Blogging, article creation, social media integration',
      example: 'HubSpot, Buffer',
      costEstimate: '$3,000 to $10,000+',
    },
    {
      type: 'Technical SEO',
      functionalities: 'Site speed, mobile-friendliness, structured data',
      example: 'Google Search Console, Screaming Frog',
      costEstimate: '$2,000 to $8,000+',
    },
    {
      type: 'Link Building',
      functionalities: 'Backlink strategy, outreach, guest posting',
      example: 'BuzzStream, Hunter.io',
      costEstimate: '$5,000 to $20,000+',
    },
    {
      type: 'Local SEO',
      functionalities: 'Google My Business, local citations, review management',
      example: 'Yext, BrightLocal',
      costEstimate: '$1,000 to $6,000+',
    },
    {
      type: 'Analytics & Reporting',
      functionalities: 'Track rankings, traffic analysis, conversion tracking',
      example: 'Google Analytics, Data Studio',
      costEstimate: '$500 to $3,000+',
    },
];

const SeoDevelopmentPage = () => {
  return (
    <section className="py-16 px-10 bg-white">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center mb-12">SEO Development</h1>

        <div className="flex flex-col md:flex-row">
          {/* Left Column */}
          <div className="md:w-1/2 md:pr-4 mb-8">
            <h2 className="text-2xl font-semibold mb-4">About SEO Services</h2>
            <p className="text-gray-700 mb-4">
              Our SEO services are designed to improve your search engine visibility and drive qualified traffic to your website. We utilize a combination of keyword research, on-page optimization, and link-building strategies.
            </p>
            <h3 className="text-lg font-semibold mb-2">Services We Offer:</h3>
            <ul className="list-disc list-inside mb-4">
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Comprehensive SEO Audits
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Keyword Research and Analysis
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                On-Page Optimization
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Link Building
              </li>
            </ul>
          </div>

          {/* Right Column */}
          <div className="md:w-1/2 md:pl-4 mb-8">
            <img src={seo} alt="SEO Services" className="rounded-lg shadow-lg" />
          </div>
        </div>
        
        <h2 className="text-2xl font-semibold text-center my-8 underline">Our Pricing</h2>
        <div className="overflow-x-auto px-20 pt-5">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-gray-600">
                <th className="border border-gray-300 px-4 py-2">Type</th>
                <th className="border border-gray-300 px-4 py-2">Functionalities</th>
                <th className="border border-gray-300 px-4 py-2">Example</th>
                <th className="border border-gray-300 px-4 py-2">Cost Estimate</th>
              </tr>
            </thead>
            <tbody>
              {seoData.map((item, index) => (
                <tr key={index} className="hover:bg-white">
                  <td className="border border-gray-300 px-4 py-2">{item.type}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.functionalities}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.example}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.costEstimate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8">Technology We Use</h2>
        <p className="text-gray-700 text-center mb-4">
          We use the latest SEO tools and platforms to ensure optimal performance and ranking.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 my-8 mx-auto max-w-4xl">
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-search text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">SEMrush</h3>
            <p className="text-gray-600">All-in-one SEO tool for keyword research and competitive analysis.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-chart-line text-green-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Google Analytics</h3>
            <p className="text-gray-600">Track and analyze website traffic and conversions.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-link text-gray-700 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Ahrefs</h3>
            <p className="text-gray-600">Advanced backlink research and rank tracking tool.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-desktop text-blue-600 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Yoast SEO</h3>
            <p className="text-gray-600">Popular WordPress plugin for on-page SEO optimization.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-file-alt text-indigo-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Screaming Frog</h3>
            <p className="text-gray-600">SEO spider tool for technical audits and site crawls.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-mobile-alt text-green-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Google Search Console</h3>
            <p className="text-gray-600">Monitor and maintain site performance in search results.</p>
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8">Understand the SEO Process</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-gray-700 text-center mb-8 max-w-5xl mx-auto">
          <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
            <i className="fas fa-key text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">01. Keyword Research</h3>
            <p>Identify target keywords to improve site ranking.</p>
          </div>

          <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
            <i className="fas fa-code text-green-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">02. On-Page Optimization</h3>
            <p>Improve meta tags, images, and overall content relevance.</p>
          </div>

          <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
            <i className="fas fa-link text-gray-700 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">03. Link Building</h3>
            <p>Gain high-quality backlinks to increase authority.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SeoDevelopmentPage;
