import React from "react";
import { Link } from "react-router-dom";

const services = [
  {
    title: "Web Development",
    description: "We build responsive and high-performing websites tailored to your needs.",
    icon: "🌐",
    link: "/web-development", // Add the route
  },
  {
    title: "App Development",
    description: "We create seamless and user-friendly mobile applications for various platforms.",
    icon: "📱",
    link: "/app-development",
  },
  {
    title: "Logo Designing",
    description: "Get professional and creative logos designed to represent your brand.",
    icon: "🎨",
    link: "/logo-designing",
  },
  {
    title: "Digital Marketing",
    description: "Boost your online presence with our expert digital marketing services.",
    icon: "📈",
    link: "/digital-marketing",
  },
];

const Services = () => {
  return (
    <section className="mt-16" aria-label="Our Services">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold text-center mb-4">Our Services</h1>
        <p className="text-center text-xl text-gray-600 mb-8">
          Explore our range of professional services to elevate your business.
        </p>

        <div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6"
          role="list"
        >
          {services.map((service, index) => (
            <Link
              to={service.link} // Use Link to navigate
              key={index}
              className="group relative bg-white shadow-[0_4px_8px_rgba(0,0,0,0.1),0_-4px_8px_rgba(0,0,0,0.05)] rounded-lg p-6 text-center hover:bg-primary1 hover:shadow-[0_6px_12px_rgba(0,0,0,0.15),0_-6px_12px_rgba(0,0,0,0.1)] transition-all duration-300"
              role="listitem"
            >
              <div
                className="text-5xl mb-4"
                aria-hidden="true"
                title={`${service.title} Icon`}
              >
                {service.icon}
              </div>
              <h2 className="text-xl font-semibold mb-2">{service.title}</h2>
              <p className="text-gray-600 group-hover:text-white transition-colors duration-300">
                {service.description}
              </p>
            </Link>
          ))}
        </div>

        {/* <div className="flex justify-center mt-8">
          <a
            href="/services"
            className="bg-primary1 text-white py-2 px-8 rounded-md shadow-md hover:bg-primary1-dark transition-all duration-300"
            aria-label="View more about our services"
          >
            View More
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Services;
