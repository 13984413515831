import React from 'react';

const Footer = () => {
  return (
    <footer class="bg-primary1 text-white py-10 rounded-tl-[220px] mt-16">
  <div class="container mx-auto px-6 grid grid-cols-1 md:grid-cols-4 gap-8 pl-28">
    <div>
      <h3 class="text-xl font-bold mb-4">ShivayTech</h3>
      <p class="text-gray-200">
      We believe in delivering high-quality projects at competitive prices, ensuring customer satisfaction and lasting partnerships.
      </p>
    </div>
    <div>
      <h3 class="text-xl font-bold mb-4">Links</h3>
      <ul class="space-y-2">
        <li><a href="#" class="hover:text-gray-300">Home</a></li>
        <li><a href="#" class="hover:text-gray-300">About</a></li>
        <li><a href="#" class="hover:text-gray-300">Services</a></li>
        <li><a href="#" class="hover:text-gray-300">Contact Us</a></li>
      </ul>
    </div>
    <div>
      <h3 class="text-xl font-bold mb-4">Subscribe</h3>
      <form class="space-y-4">
        <input
          type="email"
          placeholder="Enter email"
          class="w-full px-4 py-2 bg-primary1 border-b border-white text-white placeholder-gray-300 focus:outline-none focus:border-gray-300"
        />
        <button
          type="submit"
          class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-full w-full"
        >
          Subscribe
        </button>
      </form>
    </div>
    <div>
      <h3 class="text-xl font-bold mb-4">Address</h3>
      <ul class="space-y-2">
        <li class="flex items-center space-x-2">
          <span className='w-5'>📍</span>
          <span>Beed</span>
        </li>
        <li class="flex items-center space-x-2">
          <span className='w-5'>📞</span>
          <span>8412841282</span>
        </li>
        <li class="flex items-center space-x-2">
          <span className='w-5'>📧</span>
          <span>Shivaytech25@gmail.com</span>
        </li>
      </ul>
    </div>
  </div>
  <div class=" border-purple-500 mt-10 pt-4 text-center">
    <p class="text-gray-300 text-sm">
      © 2025 All Rights Reserved By ShivayTech
    </p>
  </div>
</footer>

  );
};

export default Footer;
