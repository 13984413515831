import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const isHomePage = location.pathname === '/';

  return (
    <header
      className={`${
        isHomePage ? 'bg-primary1' : 'bg-primary1 rounded-br-[250px]'
      } text-white pl-6 pr-10`}
    >
      <div className="container mx-auto">
        <nav className="flex justify-between items-center py-4">
          {/* Logo */}
          <Link
            className="text-2xl font-bold"
            to="/"
            aria-label="Navigate to ShivayTech homepage"
          >
            <span>ShivayTech</span>
          </Link>

          {/* Hamburger Menu (Mobile View) */}
          <button
            className="lg:hidden block text-white focus:outline-none"
            onClick={() => setIsSidebarOpen(true)}
            aria-label="Open navigation menu"
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>

          {/* Navigation Links for Large Screens */}
          <ul className="hidden lg:flex lg:space-x-6">
            {['/', '/about', '/services', '/contact'].map((path, index) => (
              <li key={index}>
                <Link
                  className={`px-3 py-2 rounded ${
                    location.pathname === path
                      ? 'bg-white text-black'
                      : 'text-white hover:text-primary2'
                  }`}
                  to={path}
                  aria-label={`Navigate to ${path.slice(1) || 'Home'} page`}
                >
                  {path === '/' ? 'Home' : path.slice(1).charAt(0).toUpperCase() + path.slice(2)}
                </Link>
              </li>
            ))}
          </ul>

          {/* Quote Button (Hidden on Small Screens) */}
          {isHomePage && (
            <div className="hidden lg:block">
              <Link
                to="/get-a-quate"
                className="bg-primary2 text-white px-4 py-2 rounded hover:bg-white hover:text-primary2 transition duration-300"
                aria-label="Get a Quote | ShivayTech"
              >
                Get A Quote
              </Link>
            </div>
          )}
        </nav>
      </div>

      {/* Sidebar (Mobile View) */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          aria-label="Mobile navigation menu overlay"
        >
          <div className="w-64 bg-primary1 h-full text-white shadow-lg">
            <button
              className="p-4 text-right focus:outline-none"
              onClick={() => setIsSidebarOpen(false)}
              aria-label="Close navigation menu"
            >
              <svg
                className="w-6 h-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <ul className="space-y-4 px-6 pt-6">
              {['/', '/about', '/services', '/contact'].map((path, index) => (
                <li key={index}>
                  <Link
                    className={`block px-3 py-2 rounded ${
                      location.pathname === path
                        ? 'bg-white text-black'
                        : 'text-white hover:text-primary2'
                    }`}
                    to={path}
                    onClick={() => setIsSidebarOpen(false)}
                    aria-label={`Navigate to ${path.slice(1) || 'Home'} page`}
                  >
                    {path === '/' ? 'Home' : path.slice(1).charAt(0).toUpperCase() + path.slice(2)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
