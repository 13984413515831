import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import logoImg from "../assets/img/graphics.jpeg";

const logoData = [
  {
    type: 'Brand Logo',
    functionalities: 'Custom logo creation, brand identity, vector files',
    example: 'Adobe Illustrator, CorelDRAW',
    costEstimate: '$500 to $2,000+',
  },
  {
    type: 'Typography Logo',
    functionalities: 'Font-based designs, custom typography, creative text logos',
    example: 'FontForge, Photoshop',
    costEstimate: '$300 to $1,500+',
  },
  {
    type: 'Illustrative Logo',
    functionalities: 'Unique illustrations, hand-drawn elements, artistic touch',
    example: 'Procreate, Adobe Illustrator',
    costEstimate: '$700 to $3,000+',
  },
  {
    type: 'Minimalist Logo',
    functionalities: 'Sleek designs, modern aesthetics, impactful simplicity',
    example: 'Canva, Figma',
    costEstimate: '$400 to $1,200+',
  },
  {
    type: '3D Logo',
    functionalities: 'Realistic 3D renderings, motion graphics, dynamic designs',
    example: 'Blender, Cinema 4D',
    costEstimate: '$1,000 to $5,000+',
  },
];

const LogoDesigningPage = () => {
  return (
    <section className="py-16 px-10 bg-white">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center mb-12">Logo Designing Services</h1>

        <div className="flex flex-col md:flex-row">
          {/* Left Column */}
          <div className="md:w-1/2 md:pr-4 mb-8">
            <h2 className="text-2xl font-semibold mb-4">About Logo Designing</h2>
            <p className="text-gray-700 mb-4">
              Our logo designing services focus on creating unique and memorable logos that align with your brand identity.
              Whether you need a modern, minimalist, or illustrative logo, our expert designers deliver results that leave a lasting impression.
            </p>
            <h3 className="text-lg font-semibold mb-2">Types of Logos We Create:</h3>
            <ul className="list-disc list-inside mb-4">
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Brand Logos
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Typography Logos
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Illustrative Logos
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Minimalist Logos
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                3D Logos
              </li>
            </ul>
          </div>

          {/* Right Column */}
          <div className="md:w-1/2 md:pl-4 mb-8">
            <img src={logoImg} alt="Logo Designing Services" className="rounded-lg shadow-lg" />
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8 underline">Our Pricing</h2>
        <div className="overflow-x-auto px-20 pt-5">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-gray-600">
                <th className="border border-gray-300 px-4 py-2">Service Type</th>
                <th className="border border-gray-300 px-4 py-2">Functionalities</th>
                <th className="border border-gray-300 px-4 py-2">Example Tools</th>
              </tr>
            </thead>
            <tbody>
              {logoData.map((item, index) => (
                <tr key={index} className="hover:bg-white">
                  <td className="border border-gray-300 px-4 py-2">{item.type}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.functionalities}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.example}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8">Technology We Use</h2>
        <p className="text-gray-700 text-center mb-4">
          We use state-of-the-art tools and software to deliver high-quality logo designs.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 my-8 mx-auto max-w-4xl">
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-pencil-alt text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Adobe Illustrator</h3>
            <p className="text-gray-600">Industry-leading tool for vector designs and logos.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-paint-brush text-red-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Procreate</h3>
            <p className="text-gray-600">Create stunning illustrative logos with ease.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-shapes text-green-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">CorelDRAW</h3>
            <p className="text-gray-600">Comprehensive design tool for professional logos.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoDesigningPage;
