import React, { useState } from 'react';

const websiteTypes = [
  {
    name: 'E-Commerce Website',
    image: 'Shivaytech-E-Commerce website.jpg',
    description: 'An eCommerce website allows you to sell products from your online store with the best web design. You can easily manage products, payments, and shipments.',
  },
  {
    name: 'School Website',
    image: 'Shivaytech-Business website.png', 
    description: 'A school website provides important information for students, parents, and teachers. It includes features like announcements, schedules, student portals, and event updates.',
  },
  {
    name: 'Blog Website',
    image: 'Shivaytech-Blog website.jpg',
    description: 'A blog website is perfect for sharing your thoughts, experiences, and expertise. It allows you to engage with an audience through articles, photos, and media content.',
  },
  {
    name: 'Politics Website',
    image: 'Shivaytech-politics website.png', // Replace this with the actual image file name
    description: 'A politics website is designed to showcase campaigns, events, and initiatives. It helps connect with supporters, share updates, and highlight political goals effectively.',
  },
  {
    name: 'Event Website',
    image: 'Shivaytech-Event website.jpg',
    description: 'An event website highlights upcoming events, allows attendees to register online, and provides all the necessary details to make the event a success.',
  },
  {
    name: 'Portfolio Website',
    image: 'Shivaytech-Portfolio website.jpg',
    description: 'A portfolio website showcases your work and achievements professionally. It is the ideal solution for artists, freelancers, and agencies to display their projects.',
  },
];

const WebsiteTypes = () => {
  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <section className="website-types py-14 px-4 sm:px-10">
      <div className="container mx-auto">
        {/* Top Heading */}
        <header className="text-center mb-12">
          <h3 className="text-3xl font-bold mb-4 text-gray-800">Types of Website Development We Provide</h3>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            Discover the different types of websites we specialize in to help you achieve your online goals, from e-commerce to personal branding.
          </p>
        </header>

        {/* Grid Layout */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {websiteTypes.map((type, index) => (
            <div
              key={index}
              aria-label={`Card for ${type.name}`}
              className={`relative card bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 will-change-transform mb-5 ${hoverIndex === index ? 'scale-105 shadow-2xl' : ''}`}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {/* Image */}
              <img
                src={type.image}
                alt= 'ShivayTech Innovation'
                className="w-full h-96 object-cover"
                style={{ objectFit: 'cover' }}
              />
              
              {/* Hovered Content */}
              <div
                className={`absolute inset-0 bg-black bg-opacity-75 p-6 flex flex-col justify-center items-center text-white text-center transition-opacity duration-300 ${hoverIndex === index ? 'opacity-100' : 'opacity-0'}`}
              >
                <h4 className="text-2xl font-bold mb-2">{type.name}</h4>
                <p className="text-sm lg:text-base">{type.description}</p>
              </div>

              {/* Website Type Name */}
              <div className={`p-4 text-center transition-opacity duration-300 ${hoverIndex === index ? 'opacity-0' : 'opacity-100'}`}>
                <h4 className="text-xl font-semibold text-gray-800">{type.name}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WebsiteTypes;
