import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const GetQuote = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    services: [],
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        services: checked
          ? [...prev.services, value]
          : prev.services.filter((service) => service !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('Sending...');

    const templateParams = {
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      services: formData.services.join(', ') || 'None selected',
    };

    emailjs
      .send(
        'service_0mk6tt9', // Replace with your EmailJS Service ID
        'template_5iwnpir', // Replace with your EmailJS Template ID
        templateParams,
        'h3BnGxAvNHsLcXf0O' // Replace with your EmailJS User ID
      )
      .then(
        () => {
          setMessage('Your quote has been sent successfully!');
          setFormData({ name: '', email: '', mobile: '', services: [] });
        },
        (error) => {
          console.error('EmailJS error:', error);
          setMessage('Failed to send the quote. Please try again.');
        }
      );
  };

  return (
    <div className=" min-h-screen flex items-center justify-center px-4 py-10">
      <div className="max-w-2xl w-full bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-extrabold text-blue-800 text-center mb-6">Get a Free Quote</h1>
        <p className="text-center text-gray-600 mb-6">
          Let us help you bring your ideas to life. Fill out the form below and we’ll get back to you
          with a tailored quote.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-blue-700 font-semibold mb-2">Name *</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 p-3 rounded focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div>
            <label className="block text-blue-700 font-semibold mb-2">Email *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 p-3 rounded focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div>
            <label className="block text-blue-700 font-semibold mb-2">Mobile *</label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
              className="w-full border border-gray-300 p-3 rounded focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div>
            <label className="block text-blue-700 font-semibold mb-2">Services</label>
            <div className="space-y-2">
              {['Web Development', 'Logo Design', 'Digital Marketing'].map((service, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    name="services"
                    value={service}
                    onChange={handleChange}
                    className="mr-2 focus:ring-2 focus:ring-blue-400"
                  />
                  <label className="text-gray-700">{service}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center space-x-4">
            <button
              type="submit"
              className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 shadow transition-transform transform hover:scale-105"
            >
              Get Quote
            </button>
            <button
              type="reset"
              onClick={() => setFormData({ name: '', email: '', mobile: '', services: [] })}
              className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 shadow transition-transform transform hover:scale-105"
            >
              Reset
            </button>
          </div>
          {message && <p className="text-center text-blue-800 font-semibold mt-4">{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default GetQuote;
